<template>
  <div class="contents userMgt users">
    <div class="title flexB">
      <h2>작물재배정보 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <!-- <option disabled selected value>선택</option> -->
          <option disabled value="title">제목</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>
      <div class="flex">
        <p class="bold">카테고리</p>
        <label>
          <input type="radio" value="" checked v-model="category" />전체
        </label>
        <label
          ><input type="radio" value="딸기" v-model="category" />딸기
        </label>
        <label
          ><input type="radio" value="토마토" v-model="category" />토마토
        </label>
        <label
          ><input type="radio" value="사과" v-model="category" />사과
        </label>
        <label
          ><input type="radio" value="고추" v-model="category" />고추
        </label>
        <label
          ><input type="radio" value="포도" v-model="category" />포도
        </label>
        <label
          ><input type="radio" value="레드향" v-model="category" />레드향
        </label>
        <label
          ><input type="radio" value="상추" v-model="category" />상추
        </label>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">
        작물재배정보 리스트
        <button class="right basic">
          <router-link to="/admin/cultureInfoRegister"> 등록</router-link>
        </button>
      </h2>
      <table>
        <colgroup>
          <col style="width: 5%" />
          <col style="width: 20%" />
          <col style="width: 55%" />
          <col style="width: 20%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <th>카테고리</th>
          <th style="text-align: left">제목</th>
          <th>등록일</th>
        </tr>
        <tr v-for="(data, i) in cultureinfoList" :key="i">
          <td>{{ total + 1 - (i + 1 + (currentPage - 1) * 10) }}</td>
          <td>{{ data.category }}</td>
          <td style="text-align: left">
            <router-link to @click.native="handleDetail(data._id)">{{
              data.title
            }}</router-link>
          </td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="cultureinfoList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchCultureInfoList } from "@/api/admin";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";

export default {
  components: { NoData },
  mixins: [format],
  data() {
    return {
      moment: moment,
      inputMode: "title",
      searchWord: "",
      category: "",
      cultureinfoList: [],
      total: 0,
      currentPage: 1,
      isNodata: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "4");
  },
  mounted() {
    this.getcultureinfoList();
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleDetail(id) {
      this.$router.push({ name: "cultureInfoRegister", query: { id: id } });
    },

    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.cultureinfoList = [];
      this.total = 0;
      this.currentPage = 1;
      this.getcultureinfoList();
    },
    search() {
      if (this.searchWord != "" && this.inputMode == "") {
        this.openAlert("검색 조건을 선택해 주세요.");
        return;
      }
      this.currentPage = 1;
      this.getcultureinfoList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getcultureinfoList();
    },
    getcultureinfoList() {
      let params = {
        page: this.currentPage,
        inputMode: this.inputMode,
        searchWord: this.searchWord,
        category: this.category,
      };
      fetchCultureInfoList(params).then((res) => {
        if (res.data.status == 200) {
          this.cultureinfoList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
          this.currentPage = res.data.page;
        } else if (res.data.status == 409) {
          this.$router.push("/");
          return this.openAlert(res.data.message);
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
